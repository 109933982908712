<template>
  <div
    :class="[
      { 'sale-page-one': $route.path == '/sale-page-one' },
      { 'sale-page-two': $route.path == '/sale-page-two' },
    ]"
  >
    <div class="white--text top-bar" :class="{ 'd-none': $route.path == '/home-two' }">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title>
            <div class="mr-2 d-md-block">
              <span class="text-sm mr-5">Connect with us:</span>
              <v-icon class="mr-2 white--text" small>mdi-phone</v-icon>
              <span class="text-sm mr-5">P/ +61 2 9252 5222</span>
              <v-icon class="mr-2 white--text" small>mdi-email</v-icon>
              <span class="text-sm">E/ sales@itquoter.com</span>
            </div>
          </v-toolbar-title>
          <v-toolbar-title>
            <div class="mr-2 d-md-block">
              <span class="text-sm mr-5">Company</span>
              <span class="text-sm">Logout</span>
            </div>
          </v-toolbar-title>
        </div>
      </v-container>
    </div>
    <v-app-bar app scroll-off-screen class="sub-topbar" color="white py-3">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-col cols="12" md="3" class="pa-0">
            <v-toolbar-title class="d-flex">
              <router-link to="/">
                <v-img
                  class="me-2 logo d-md-block"
                  width="160"
                  src="@/assets/images/your-logo.png"
                  alt=""
                ></v-img>
              </router-link>
              <div class="dropdown-ecommerce">
                <HeaderNavbar />
              </div>
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" md="3">
            <div class="d-flex">
              <v-col cols="12" md="9" class="pa-0 mr-5">
                <div
                  v-if="$route.path == '/home-two'"
                  class="search-bar d-flex p-relative"
                >
                  <v-text-field
                    class=""
                    placeholder="Searching For"
                    filled
                    rounded
                    hide-details
                    dense
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    class="text-capitalize search-bar-dropdown px-10 font-600"
                  >
                    Search
                  </v-btn>
                </div>

                <div v-else class="search-bar d-flex p-relative">
                  <v-text-field
                    class=""
                    placeholder="Searching For"
                    filled
                    rounded
                    dense
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      fab
                      small
                      class="mr-3"
                      color="grey lighten-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>

                  <base-card>
                    <div class="px-3 px-md-10 py-8">
                      <h3 class="mb-2 text-center">Welcome To Ecommerce</h3>
                      <h5
                        class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
                      >
                        Log in with email & password
                      </h5>
                      <p class="text-14 mb-1">Email or Phone Number</p>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        placeholder="example@mail"
                        class="mb-4"
                      ></v-text-field>
                      <p class="text-14 mb-1">Password</p>
                      <v-text-field
                        outlined
                        dense
                        type="password"
                        hide-details
                        placeholder="example@mail"
                        class="mb-4"
                      ></v-text-field>
                      <v-btn block color="primary" class="text-capitalize font-600"
                        >Login</v-btn
                      >
                      <v-col cols="10" lg="8" class="mx-auto">
                        <div class="d-flex align-center my-1">
                          <v-divider></v-divider>
                          <span class="mx-4">on</span>
                          <v-divider></v-divider>
                        </div>
                      </v-col>
                      <v-btn
                        block
                        dark
                        color="indigo"
                        class="text-capitalize font-600 mb-4"
                      >
                        <v-icon left class="me-3">mdi-facebook</v-icon>
                        Continue with facebook
                      </v-btn>
                      <v-btn
                        block
                        dark
                        color="blue darken-2"
                        class="text-capitalize font-600 mb-4"
                      >
                        <v-icon left class="me-3">mdi-google</v-icon>
                        Continue with Google
                      </v-btn>
                      <div class="text-14 text-center my-3">
                        Don't have account?
                        <router-link
                          to="/sign-up"
                          class="grey--text text--darken-4 font-600"
                          >Sign Up</router-link
                        >
                      </div>
                    </div>
                    <div class="py-4 grey lighten-2">
                      <div class="text-center">
                        <span class="grey--text text--darken-1"
                          >Forgot Your Password
                          <router-link
                            to="/"
                            class="ms-2 grey--text text--darken-4 font-600"
                            >Reset It</router-link
                          >
                        </span>
                      </div>
                    </div>
                  </base-card>
                </v-dialog>
                <!-- cartCount  -->
                <v-badge
                  bordered
                  color="error"
                  :content="getCartProducts.length == 0 ? '0' : getCartProducts.length"
                  overlap
                >
                  <v-btn
                    @click="drawer = true"
                    elevation="0"
                    fab
                    color="grey lighten-2"
                    small
                  >
                    <v-icon color="">mdi-cart</v-icon>
                  </v-btn>
                </v-badge>
              </v-col>
            </div>
          </v-col>
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary right width="320" class="z-999">
      <div class="secondary-siebar-content">
        <v-list-item>
          <v-list-item-avatar class="mr-0">
            <v-icon color="">mdi-shopping-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class=""
              >{{ getCartProducts.length }} Items</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="" @click="drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="getCartProducts.length >= 1">
          <div v-for="(n, index) in getCartProducts" :key="index">
            <div class="cart-item d-flex justify-space-between align-center px-2 py-3">
              <div class="d-flex flex-column align-center">
                <v-btn
                  @click="addCartx(n)"
                  class="mx-2"
                  fab
                  outlined
                  dark
                  x-small
                  color="primary"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <span>{{ n.qty }}</span>
                <v-btn
                  @click="removeCartx(n)"
                  class="mx-2"
                  fab
                  outlined
                  dark
                  x-small
                  color="primary"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
              </div>
              <v-avatar tile size="76">
                <img :src="n.img" alt="" />
              </v-avatar>
              <div class="col-5">
                <h5 class="text-truncate">{{ n.title }}</h5>
                <p class="text-tiny">${{ n.amount }} x {{ n.qty }}</p>
                <h5 class="primary--text">{{ n.amount * n.qty }}</h5>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div v-else>
          <div
            class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10"
          >
            <v-avatar size="90" tile class="mb-8">
              <img src="@/assets/images/empty-cart.png" alt="" />
            </v-avatar>
            <p class="grey--text text--darken-2 px-5 mx-10 text-center">
              Your shopping bag is empty. Start shopping
            </p>
          </div>
        </div>
      </div>
      <template v-slot:append v-if="getCartProducts.length >= 1">
        <div class="pa-2">
          <v-btn
            to="/checkout-alternative"
            class="text-capitalize mb-3"
            block
            color="primary"
          >
            Checkout Now (${{ cartTotal }})
          </v-btn>
          <v-btn to="/cart" class="text-cappitalise" outlined block color="primary">
            View Cart
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar class="navbar" :class="{ 'd-none': $route.path == '/sale-page-two' }">
      <div class="container">
        <div class="d-flex justify-space-between align-center">
          <div
            id="navbar-toggle-dropdown"
            class="navbar-toggle-dropdown p-absolute"
            :class="{ open: isToggleNavbar }"
          >
            <Navbar />
          </div>
          <div>
            <ul class="navbar-nav navigation-navbar d-flex flex-row pa-0">
              <li class="nav-item me-3">
                <router-link to="/">Home</router-link>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="#"
                  >Products
                  <v-icon class="float-right ml-1 mr-n2">mdi-chevron-down</v-icon></a
                >
                <ul class="font-weight-medium">
                  <li>
                    <router-link to="/search-by-spec">
                      <p class="mb-0">Search by Spec</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/micro-catalogue">
                      <p class="mb-0">Micro Catalogue</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/my-favourites">
                      <p class="mb-0">My Favourites</p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="/quotes">Quotes</a>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="/requisitions">Requisitions</a>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="/my-agreements">My Agreements</a>
              </li>
              <!-- <li class="nav-item me-3">
                <a class="nav-link" href="#">Reports <v-icon class="float-right ml-1 mr-n2">mdi-chevron-down</v-icon></a>
                <ul class="font-weight-medium">
                  <li>
                    <router-link to="/dashboard">
                      <p class="mb-0">Quotes Summary</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-list">
                      <p class="mb-0">Orders Summary</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/edit-product">
                      <p class="mb-0">Ship Today</p>
                    </router-link>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderNavbar from "@/views/home/header-navbar";
import Navbar from "@/views/home/navbar";
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "BaseAppBar",
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    HeaderNavbar,
    Navbar,
  },

  computed: {
    ...mapGetters(["getCartProducts"]),
    cartTotal() {
      let total = 0;
      this.getCartProducts.forEach((product) => {
        total += product.amount * product.qty;
      });
      return total;
    },
  },
  data: () => ({
    cartCount: 0,
    colorNav: false,
    dialog: false,
    isToggleNavbar: false,
    items: [
      { title: "Car" },
      { title: "Clothes" },
      { title: "Electronics" },
      { title: "Laptop" },
    ],
    itemTwo: ["Foo", "Bar", "Fizz", "Buzz"],
    drawer: false,
    group: null,
  }),

  methods: {
    ...mapActions(["addCart", "removeCart"]),

    toggleNavbar() {
      if (this.$route.path == "/") {
        this.isToggleNavbar = false;
      } else {
        this.isToggleNavbar = !this.isToggleNavbar;
      }
    },
    away() {
      this.isToggleNavbar = false;
    },
    removeCartx(item) {
      this.removeCart(item);
    },
    addCartx(item) {
      this.addCart(item);
    },
  },
};
</script>

<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}
.top-bar {
  background-color: #1268b3;
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  width: 100%;
  height: auto !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    box-shadow: rgba(43, 52, 69, 0.1) 0px 16px 16px -16px !important;
    position: fixed;
    top: 0;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgba(43, 52, 69, 0.9) 0px 8px 12px -16px !important;
  background-color: #fff !important;
  border-top: 1px solid #eceff3;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgba(43, 52, 69, 0.9) 0px 8px 12px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  ::v-deep .v-input__slot {
    border: 1px solid rgb(218, 225, 231);
    background-color: #fff !important;
  }
  .search-bar-dropdown {
    height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;
    border-radius: 22px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid rgb(218, 225, 231) !important;
    box-shadow: none !important;
    @media (max-width: $md) {
      display: none;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.v-hidden {
  visibility: hidden;
}
.empty-cart-sidebar {
  height: 80vh;
}
</style>
